.skuList {
    display: list-item;
}

.skuList li {
    display: list-item;
}

.contentsBanner {
    background-color: red;
}

.contentsBannerTitle {
    color: white;
    font-size: 5vw;
}

.contentsBannerLogo {
    /* margin-top: 2vh; */
    width: 15vw;
    /* display: block;
    height: auto;
    max-width: 100%;
    margin-left:auto;
    margin-right:auto; */
    margin-top: 10%;
}

.contentsBannerImage {
    /* margin-top: 2vh; */
    width: 95vw;
    /* display: block;
    height: auto;
    max-width: 100%;
    margin-left:auto;
    margin-right:auto; */
    margin-top: 5%;
}

.contentHeaderSubtitle {
    font-size: 2.6vw;
}

.boxTitle {
    font-size: 3vw;
    font-weight: bold;
    margin-top: 4px;
    padding-left: 2px;
    padding-bottom: 5px;
}

.boxSubTitle {
    font-size: 3vw;
    font-weight:600;
    margin-top: 20px;
    padding-bottom: 12px;
    text-align: center;
    background-color: lightgray;
}

.boxTitleContainer {
    margin-left: 2px;
    margin-right: 2px;
}

.anotherHamperBtn {
    margin: 4px 4px auto auto;
    font-size: 2vw;
}

.boxLabel {
    font-size: 1.5vw;
    font-weight: 300;
    text-align: left;
    /* padding-left: 1.5vw; */
}

.hamperSearch {
    /* width: 80vw; */
    width: 100%;
}

.contentsInstaLogo {
    width: 2.3vw;
}

.contentsFacebookLogo {
    width: 2.3vw;
}